import React from "react"
import {Placeholder} from "react-bootstrap"

export const Placeholders = () => {
  const gend = [1, 2, 3, 4]
  const symb = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]

  const gender_p = gend.map((item) => {
    return (
      <Placeholder className="item_gender" as="p" key={item} animation="glow">
        <Placeholder style={{width: "100%", height: "100%"}} />
      </Placeholder>
    )
  })

  const symbol_p = symb.map((item) => {
    return (
      <Placeholder className="item_symbol" as="p" key={item} animation="glow">
        <Placeholder style={{width: "100%", height: "100%"}} />
      </Placeholder>
    )
  })

  const names_p = symb.map((item) => {
    return (
      <Placeholder className="item_name_p" as="p" key={item} animation="glow">
        <Placeholder style={{width: "100%", height: "100%"}} />
      </Placeholder>
    )
  })

  return (
    <div className="container-fluid d-flex justify-content-center">
      <div className="col-lg-8">
        <div className="all_genders">{gender_p}</div>
        <div className="all_symbols">{symbol_p}</div>
        <div className="all_name">{names_p}</div>
      </div>
    </div>
  )
}
