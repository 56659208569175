import React, {useEffect, useState} from "react"
import {Placeholders} from "../Placeholders"

import useFetch from "../../api/useFetch"

export const Features = () => {
  const _gender = useFetch("names/v1/gender/", "async_gender")
  const _symbol = useFetch("names/v1/symbol/", "async_symbol")
  const _name = useFetch("names/v1/", "async_name")
  const [gender, setGender] = useState(0)
  const [symbol, setSymbol] = useState(null)
  const [datas, setDatas] = useState([])

  useEffect(() => {
    if (_name.data) {
      gender === 0 && !symbol
        ? setDatas(_name.data)
        : !symbol
        ? setDatas(_name.data.filter((x) => x.gender === gender))
        : gender === 0
        ? setDatas(_name.data.filter((x) => x.symbol === symbol))
        : setDatas(
            _name.data.filter((x) => x.symbol === symbol && x.gender === gender)
          )
    }
  }, [gender, symbol, _name.data])

  if (!_gender.data || !_symbol.data || !_name.data) {
    return <Placeholders />
  }
  const symbols = _symbol.data.map((item) => {
    return (
      <div
        className="item_symbol"
        key={item.id}
        style={{
          backgroundColor: symbol === item.symbol ? "red" : "transparent",
        }}
        onClick={() => setSymbol(item.symbol)}
      >
        {item.symbol}
      </div>
    )
  })
  const genders = _gender.data.map((item) => {
    return (
      <div
        className="item_gender"
        key={item.id}
        style={{backgroundColor: gender === item.title ? "red" : "transparent"}}
        onClick={() => setGender(item.title)}
      >
        {item.title}
      </div>
    )
  })
  const names = datas.map((item) => {
    return (
      <div className="item_name" key={item.id}>
        {item.firstName}
      </div>
    )
  })

  return (
    <div className="container-fluid d-flex justify-content-center">
      <div className="col-lg-8">
        <div className="all_genders">
          <div
            className="item_gender"
            style={{
              backgroundColor: gender === 0 ? "red" : "transparent",
            }}
            onClick={() => setGender(0)}
          >
            Ӱзе
          </div>
          {genders}
          <a href="mailto:name@kabai.ru" className="link_mail">
            name@kabai.ru
          </a>
        </div>
        <div className="all_symbols">{symbols}</div>
        <div className="all_name">{names}</div>
      </div>
    </div>
  )
}
