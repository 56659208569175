import React from "react"
import {Menu} from "./components/features/Menu"
import {Features} from "./components/features"

function App() {
  return (
    <div className="App">
      <Menu />
      <Features />
    </div>
  )
}

export default App
